import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row, Typography } from 'antd';
import { ContentCustom } from '..';
import { useAuthContext } from '../../contexts/AuthContext';
import classic from '../../assets/images/classic layout.png';
import drawer from '../../assets/images/drawer.png';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const LayoutChoice = ({ routeId }) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const { getRoutes } = useBuilderContext();
  const [layout, setLayout] = useState('classic');

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await dispatchAPI('PATCH', {
        url: `routes/${routeId}`,
        body: { layout }
      });
      await getRoutes();
    } catch (e) {
      message(e);
      setIsLoading(false);
    }
  };

  return (
    <ContentCustom>
      <Typography.Title level={3}>Select a layout</Typography.Title>
      <Row style={{ margin: '24px 0' }} gutter={16}>
        <Col span={12}>
          <Card
            onClick={() => setLayout('classic')}
            hoverable
            style={{
              width: '100%',
              borderColor: layout === 'classic' ? 'var(--primaryColor)' : '',
              borderWidth: layout === 'classic' ? 4 : 1
            }}
            cover={<img src={classic} alt="" />}
          >
            <Card.Meta title="Classic" />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            style={{
              width: '100%',
              borderColor: layout === 'drawer' ? 'var(--primaryColor)' : '',
              borderWidth: layout === 'drawer' ? 4 : 1,
              cursor: 'not-allowed'
            }}
            cover={<img src={drawer} alt="" />}
          >
            <Card.Meta title="Drawer" />
          </Card>
        </Col>
      </Row>
      <Button
        type="primary"
        disabled={!layout}
        onClick={handleClick}
        loading={isLoading}
      >
        Save
      </Button>
    </ContentCustom>
  );
};

LayoutChoice.propTypes = {
  routeId: PropTypes.string.isRequired
};
