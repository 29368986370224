import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Tabs } from 'antd';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { CreateEditRelatedTabs } from './CreateEditRelatedTabs';
import { Datatable } from '../DataTable/Datatable';

export const RelatedTabs = ({ routeId, currentResourceId }) => {
  const { projectRoutes } = useBuilderContext();
  const currentRoute = projectRoutes.find((r) => r._id === routeId);
  const [currentTab, setCurrentTab] = useState();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (currentRoute?.related_tabs?.length) {
      setCurrentTab(currentRoute.related_tabs[0].resource);
    }
  }, [currentRoute]);

  return (
    <>
      <CreateEditRelatedTabs
        currentRoute={currentRoute}
        visible={visible}
        close={() => setVisible(false)}
      />
      <Row>
        <Button type="primary" onClick={() => setVisible(true)}>
          Add a related Tab
        </Button>
      </Row>
      {!!currentRoute?.related_tabs?.length && (
        <Tabs onChange={(key) => setCurrentTab(key)} activeKey={currentTab}>
          {currentRoute.related_tabs.map((relatedTab) => (
            <Tabs.TabPane tab={relatedTab.resource} key={relatedTab.resource}>
              <Datatable
                resourceName={relatedTab.resource}
                columns={relatedTab.columns}
                extraQuery={`${relatedTab.lookup_field}=${currentResourceId}`}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </>
  );
};

RelatedTabs.propTypes = {
  routeId: PropTypes.string.isRequired,
  currentResourceId: PropTypes.string.isRequired
};
