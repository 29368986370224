import React from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { useBuilderContext } from '../contexts/BuilderContext';
import { BuilderRouter } from './BuilderRouter';
import { DefaultRouter } from './DefaultRouter';

export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { project } = useBuilderContext();

  const dispatchRouter = () => {
    if (project) return <BuilderRouter />;
    switch (user?.role) {
      default:
        return <DefaultRouter />;
    }
  };
  return dispatchRouter();
};
