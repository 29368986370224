import React, { createContext, useContext, useEffect, useState } from 'react';
import { useStateWithLocalStorage } from '../utils';
import { useErrorMessage } from '../utils/ErrorMessage';
import { useAuthContext } from './AuthContext';

const BuilderContext = createContext({});

export const BuilderContextProvider = ({ children }) => {
  const { dispatchAPI, token } = useAuthContext();
  const { message } = useErrorMessage();
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useStateWithLocalStorage('project', null);
  const [routes, setRoutes] = useStateWithLocalStorage('routes', []);
  const [addRouteVisible, setAddRouteVisible] = useState(false);
  const [routeToEdit, setRouteToEdit] = useState();
  const [routeToDelete, setRouteToDelete] = useState();

  useEffect(() => {
    if (!token) {
      setProject(null);
      setRoutes([]);
    }
  }, [token]);

  const getProjects = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'projects' });
      setProjects(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (token) {
      (async () => {
        await getProjects();
      })();
    }
  }, [token]);

  const getRoutes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `routes?project=${project}`
      });
      setRoutes(data);
    } catch (e) {
      message(e);
      setRoutes([]);
    }
  };

  useEffect(() => {
    if (project) {
      (async () => {
        await getRoutes();
      })();
    }
  }, [project]);

  return (
    <BuilderContext.Provider
      value={{
        projects,
        getProjects,
        project,
        setProject,
        projectRoutes: routes,
        getRoutes,
        addRouteVisible,
        setAddRouteVisible,
        routeToEdit,
        setRouteToEdit,
        routeToDelete,
        setRouteToDelete
      }}
    >
      {children}
    </BuilderContext.Provider>
  );
};

export const useBuilderContext = () => {
  const context = useContext(BuilderContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
