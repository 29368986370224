import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, Outlet } from 'react-router-dom';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { LayoutChoice } from '../LayoutChoice/LayoutChoice';
import { DynamicListResource } from '../DynamicListResource/DynamicListResource';
import { DynamicCreateUpdate } from '../DynamicCreateUpdate/DynamicCreateUpdate';
import { DynamicShow } from '../DynamicShow/DynamicShow';

const CheckConfig = ({ routeId }) => {
  const { projectRoutes } = useBuilderContext();
  const currentRoute = projectRoutes.find((r) => r._id === routeId);

  if (!currentRoute.layout) return <LayoutChoice routeId={routeId} />;

  return <Outlet />;
};

CheckConfig.propTypes = {
  routeId: PropTypes.string.isRequired
};

export const DynamicRouteRouter = ({ routeId }) => {
  const { projectRoutes } = useBuilderContext();
  const currentRoute = projectRoutes.find((r) => r._id === routeId);

  return (
    <Routes>
      <Route element={<CheckConfig routeId={routeId} />}>
        {currentRoute.layout === 'classic' && (
          <>
            <Route
              path="/create"
              element={
                <DynamicCreateUpdate routeId={routeId} purpose="create" />
              }
            />
            <Route
              path="/edit/:id"
              element={<DynamicCreateUpdate routeId={routeId} purpose="edit" />}
            />
            <Route
              path="/show/:id"
              element={<DynamicShow routeId={routeId} />}
            />
          </>
        )}
        <Route index element={<DynamicListResource routeId={routeId} />} />
      </Route>
    </Routes>
  );
};

DynamicRouteRouter.propTypes = {
  routeId: PropTypes.string.isRequired
};
