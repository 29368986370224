import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const BackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      type="link"
      icon={<LeftOutlined />}
      style={{ marginBottom: 10 }}
      onClick={() => navigate(-1)}
    >
      {t('buttons.back')}
    </Button>
  );
};
