import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import { useHandleResize } from '../../utils/HandleResize';
import ChangePwdForm from './ChangePwdForm';
import Logo from '../../assets/images/logostrategin.png';

export const Login = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { Title } = Typography;
  const { width, height } = useHandleResize();
  const [currentForm, setCurrentForm] = useState('login');
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <div>
      <Layout style={{ minHeight: '100vh', display: 'inherit' }}>
        <Row>
          <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 16 }}>
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
              <img style={{ width: '80%' }} alt="logo" src={Logo} />
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            style={{ backgroundColor: 'var(--componentBackground)' }}
          >
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
              {width < 768 && height > 550 && (
                <img alt="logo" style={{ width: '80%' }} src={Logo} />
              )}
              {width < 768 && height <= 550 && (
                <img alt="logo" style={{ width: '60%' }} src={Logo} />
              )}
              <Col xxl={14} xs={20} style={{ textAlign: 'center' }}>
                {width > 768 && (
                  <img
                    style={{
                      width: 160,
                      marginBottom: 50
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                )}
                <Title style={{ textAlign: 'center' }}>
                  {t(`login.title.${currentForm}`)}
                </Title>
                {forms[currentForm]}
                <Row>
                  {width < 768 && height > 550 && (
                    <img
                      style={{
                        width: 120,
                        margin: '0 auto',
                        display: 'block'
                      }}
                      alt="logo"
                      src={Logo}
                    />
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </div>
  );
};
