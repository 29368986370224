import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { Button, Popconfirm, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import pluralize from 'pluralize';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom, DescriptionList } from '..';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { useDynamicListContent } from '../../utils/dynamicListContent';
import { RelatedTabs } from '../RelatedTabs/RelatedTabs';

export const DynamicShow = ({ routeId }) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [resource, setResource] = useState();
  const [schema, setSchema] = useState();
  const { projectRoutes } = useBuilderContext();
  const currentRoute = projectRoutes.find((r) => r._id === routeId);
  const content = useDynamicListContent(resource, schema);
  const baseUrl = pluralize(currentRoute.key.toLowerCase());

  const getResource = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `${baseUrl}/${id}`
      });
      setResource(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const getSchema = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `schemas/builderSchema/${currentRoute.schema_ref}`
      });
      setSchema(data.schema_object);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getResource();
      await getSchema();
      setIsLoading(false);
    })();
  }, [getResource]);

  const deleteResource = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/resources/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title="Details"
        extra={
          <>
            <Link to={{ pathname: `${pathname}/../../edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteResource}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList data={content} />
        </Skeleton>
      </ContentCustom>
      <ContentCustom>
        <RelatedTabs routeId={routeId} currentResourceId={id} />
      </ContentCustom>
    </>
  );
};

DynamicShow.propTypes = {
  routeId: PropTypes.string.isRequired
};
