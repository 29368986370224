import { Button } from 'antd';
import { useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const ExportProject = () => {
  const { dispatchAPI } = useAuthContext();
  const { project } = useBuilderContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `exports/project/${project}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `export.zip`;
      a.click();
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  return (
    <Button
      type="primary"
      onClick={handleClick}
      style={{ width: '100%' }}
      loading={isLoading}
    >
      Export Project
    </Button>
  );
};
