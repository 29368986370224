import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Col, Input, Row } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import { Datatable } from '../DataTable/Datatable';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { SchemaModal } from '../SchemaModal/SchemaModal';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { ColumnModal } from '../ColumnModal/ColumnModal';

const { Search } = Input;

export const DynamicListResource = ({
  customActionColumn,
  children,
  populate,
  extraQuery,
  forceRefresh,
  editAction,
  showAction,
  deleteAction,
  onDoubleClickAction,
  scroll,
  expandable,
  path,
  rowKey,
  routeId
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const { projectRoutes } = useBuilderContext();
  const currentRoute = projectRoutes.find((r) => r._id === routeId);
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const pageSize = params.get('pS');
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const [searchValue, setSearchValue] = useState(keyword);

  const searchResource = (value) => {
    if (value) {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}&k=${value}`
      });
    } else {
      navigate({
        pathname,
        search: `?p=1${pageSize ? `&pS=${pageSize}` : ''}${
          currentSorter ? `&s=${currentSorter}` : ''
        }${currentFilters ? `&f=${currentFilters}` : ''}`
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const handleExportResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `exports/route/${currentRoute._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `export.zip`;
      a.click();
    } catch (e) {
      message(e);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={currentRoute.key}
        extra={
          <>
            <SchemaModal currentRoute={currentRoute} />
            <Button
              type="link"
              icon={<DownloadOutlined />}
              onClick={handleExportResource}
            />
          </>
        }
      />
      <ContentCustom>
        <Row justify="space-between" gutter={[8, 16]}>
          <Col>
            <Search
              allowClear
              placeholder={t('placeholder.search')}
              defaultValue={searchValue}
              onSearch={(value) => searchResource(value)}
            />
          </Col>
          <Col>
            <Row align="middle">
              <Link to={`${pathname}/create`}>
                <Button type="add">
                  {`${t('buttons.create')}`}
                  &nbsp;
                  <PlusOutlined />
                </Button>
              </Link>
              <ColumnModal currentRoute={currentRoute} />
            </Row>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>{children}</Row>
        <Datatable
          style={{ marginTop: 24 }}
          resourceName={pluralize(currentRoute.key.toLowerCase())}
          columns={currentRoute.columns}
          customActionColumn={customActionColumn}
          extraQuery={extraQuery}
          populate={populate}
          forceRefresh={forceRefresh}
          editAction={editAction}
          showAction={showAction}
          deleteAction={deleteAction}
          onDoubleClickAction={onDoubleClickAction}
          scroll={scroll || { x: 1200 }}
          expandable={expandable}
          path={path}
          rowKey={rowKey}
        />
      </ContentCustom>
    </>
  );
};

DynamicListResource.propTypes = {
  customActionColumn: PropTypes.bool,
  extraQuery: PropTypes.string,
  populate: PropTypes.string,
  forceRefresh: PropTypes.bool,
  editAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  showAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      pathname: PropTypes.func
    })
  ]),
  deleteAction: PropTypes.bool,
  onDoubleClickAction: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func
    })
  ]),
  scroll: PropTypes.shape({}),
  expandable: PropTypes.shape({}),
  path: PropTypes.string,
  rowKey: PropTypes.string,
  routeId: PropTypes.string.isRequired
};

DynamicListResource.defaultProps = {
  extraQuery: null,
  populate: null,
  customActionColumn: false,
  forceRefresh: null,
  editAction: true,
  showAction: true,
  deleteAction: true,
  onDoubleClickAction: true,
  scroll: null,
  expandable: undefined,
  path: null,
  rowKey: '_id'
};
