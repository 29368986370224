/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Input } from 'antd';

export const useGenerateFormItem =
  () =>
  ({
    label,
    name,
    initialValue,
    dependencies,
    hasFeedback,
    validateStatus,
    rules,
    input,
    layout,
    noLabel,
    valuePropName,
    labelAlign,
    help,
    hidden = false,
    displayOnly = false
  }) =>
    (
      <div key={name}>
        <Form.Item
          {...layout}
          labelAlign={labelAlign}
          colon={false}
          hidden={hidden}
          name={displayOnly ? undefined : name}
          initialValue={initialValue}
          dependencies={dependencies}
          label={!noLabel && `${label || name.join('.')}`}
          hasFeedback={hasFeedback}
          rules={rules}
          valuePropName={valuePropName}
          validateStatus={validateStatus}
          help={help}
        >
          {input || <Input />}
        </Form.Item>
      </div>
    );
