const contentGivenFieldType = {
  String: (content) => content
};

export const useDynamicListContent = (resource, schema = {}) =>
  Object.entries(schema).map(([fieldName, { type }]) => ({
    label: fieldName,
    content: contentGivenFieldType[type](resource[fieldName]),
    span: 1
  }));
