import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const AddRoute = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const {
    project,
    getRoutes,
    addRouteVisible,
    setAddRouteVisible,
    routeToEdit,
    setRouteToEdit,
    routeToDelete,
    setRouteToDelete
  } = useBuilderContext();
  const [isLoading, setIsLoading] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const handleCancel = () => {
    setAddRouteVisible(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      await dispatchAPI(routeToEdit ? 'PATCH' : 'POST', {
        url: `routes${routeToEdit ? `/${routeToEdit}` : ''}`,
        body: { ...values, project }
      });
      await getRoutes();
      setAddRouteVisible(false);
      setRouteToEdit(undefined);
      form.resetFields();
    } catch (e) {
      setIsLoading(false);
      message(e);
    }
  };

  useEffect(() => {
    if (routeToEdit) {
      setIsLoading(true);
      setAddRouteVisible(true);
      (async () => {
        const { data } = await dispatchAPI('GET', {
          url: `routes/${routeToEdit}`
        });
        form.setFieldsValue({ ...data, path: data.path.slice(1) });
      })();
      setIsLoading(false);
    }
  }, [routeToEdit]);

  useEffect(() => {
    if (routeToDelete) {
      setDeleteVisible(true);
    }
  }, [routeToDelete]);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await dispatchAPI('DELETE', { url: `routes/${routeToDelete}` });
      setDeleteVisible(false);
      setRouteToDelete(undefined);
      await getRoutes();
      navigate('/');
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        title="Delete route"
        visible={deleteVisible}
        onCancel={() => {
          setDeleteVisible(false);
          setRouteToDelete(undefined);
        }}
        onOk={handleDelete}
        okButtonProps={{ loading: isLoading }}
      >
        Are you sure you want to delete this route?
      </Modal>
      <Modal
        visible={addRouteVisible}
        title="Add a route"
        onCancel={handleCancel}
        onOk={() => form.submit()}
        okButtonProps={{ loading: isLoading }}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name={['key']} label="Key">
            <Input />
          </Form.Item>
          <Form.Item name={['path']} label="Path">
            <Input prefix="/" />
          </Form.Item>
        </Form>
      </Modal>
      <Button
        type="dashed"
        ghost
        style={{ width: '100%' }}
        onClick={() => setAddRouteVisible(true)}
      >
        <PlusOutlined />
      </Button>
    </>
  );
};
