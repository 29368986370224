import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InsertRowRightOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useBuilderContext } from '../../contexts/BuilderContext';

export const ColumnModal = ({ currentRoute, columnId }) => {
  const { dispatchAPI } = useAuthContext();
  const { getRoutes } = useBuilderContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [schema, setSchema] = useState();
  const [schemaLoading, setSchemaLoading] = useState(false);

  const handleSubmit = async (body) => {
    setIsLoading(true);
    try {
      await dispatchAPI(columnId ? 'PATCH' : 'POST', {
        url: `routes/${currentRoute._id}/columns${
          columnId ? `/${columnId}` : ''
        }`,
        body
      });
      form.resetFields();
      setVisible(false);
      await getRoutes();
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  const getSchema = async () => {
    setSchemaLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `schemas/builderSchema/${currentRoute.schema_ref}`
      });
      setSchema(data);
    } catch (e) {
      message(e);
    }
    setSchemaLoading(false);
  };

  useEffect(() => {
    if (visible) {
      (async () => {
        await getSchema();
      })();
    }
  }, [visible]);

  return (
    <>
      <Modal
        visible={visible}
        onOk={form.submit}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        okButtonProps={{ loading: isLoading }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{ sorter: true }}
        >
          <Form.Item name={['key']} rules={[{ required: true }]} label="Key">
            <Input />
          </Form.Item>
          <Form.Item name={['title']} label="Title">
            <Input />
          </Form.Item>
          <Form.Item name={['dataIndex']} label="Data index">
            <Select
              allowClear
              loading={schemaLoading}
              options={[
                ...Object.keys(schema?.schema_object || {}),
                'created_at',
                'updated_at'
              ].map((opt) => ({
                label: opt,
                value: opt
              }))}
            />
          </Form.Item>
          <Form.Item name={['sorter']} valuePropName="checked">
            <Checkbox>Sort options</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
      <Button
        type="link"
        icon={<InsertRowRightOutlined style={{ fontSize: 18 }} />}
        onClick={() => setVisible(true)}
      />
    </>
  );
};

ColumnModal.propTypes = {
  currentRoute: PropTypes.shape({
    _id: PropTypes.string,
    schema_ref: PropTypes.string
  }).isRequired,
  columnId: PropTypes.string
};

ColumnModal.defaultProps = {
  columnId: undefined
};
