import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';

const LoginForm = ({ switchForm }) => {
  const location = useLocation();
  const { isValid, dispatchAPI, remember } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const { from } = (location && location.state) || { from: { pathname: '/' } };

  const loginUser = async (email, password, rememberMe) => {
    setLoading(true);
    try {
      await dispatchAPI('LOGIN', {
        email,
        password,
        rememberMe
      });
      message.success(t('login.success'));
    } catch (e) {
      if (e.response) {
        if (e.response.data && e.response.data.error) {
          message.error(e.response.data.error.description);
        } else {
          message.error(t('login.wrongDetails'));
        }
      } else if (e.request) {
        message.error(t('login.networkError'));
      } else {
        message.error(t('login.error'));
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    await loginUser(
      values.username.toLowerCase(),
      values.password,
      values.remember
    );
  };

  return (
    <Form onFinish={handleSubmit} initialValues={{ remember }}>
      {isValid && <Navigate to={from} />}
      <Form.Item
        name="username"
        rules={[{ required: true, message: t('login.usernameMissing') }]}
      >
        <Input
          prefix={
            <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.username')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: t('login.pwdMissing') }]}
      >
        <Input.Password
          prefix={
            <LockOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.password')}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'left' }}>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>{t('login.rememberMe')}</Checkbox>
        </Form.Item>
        <Button
          type="link"
          style={{ float: 'right', padding: 0, height: 'auto' }}
          onClick={() => switchForm('forgotPwd')}
        >
          {t('login.forgotPwd')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.connect')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          type="link"
          style={{ width: '100%' }}
          onClick={() => switchForm('register')}
        >
          {t('login.register')}
        </Button>
      </Form.Item>
    </Form>
  );
};

LoginForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};

export default LoginForm;
