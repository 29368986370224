import React, { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import pluralize from 'pluralize';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/GenerateFormItem';
import { formItemLayout } from '../../utils/constants/formLayout';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { FieldModal } from '../FieldModal/FieldModal';

export const DynamicCreateUpdate = forwardRef(({ purpose, routeId }, ref) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { projectRoutes } = useBuilderContext();
  const currentRoute = projectRoutes.find((r) => r._id === routeId);
  const baseUrl = pluralize(currentRoute.key.toLowerCase());

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${baseUrl}/${id}`,
        body
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  const createResource = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `${baseUrl}`,
        body
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  const getResource = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `${baseUrl}/${id}` });
      form.setFieldsValue(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [purpose, id, baseUrl]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      (async () => {
        await getResource();
      })();
    }
  }, [getResource]);

  const handleSubmit = async (values) => {
    if (purpose === 'edit') await updateResource(values);
    if (purpose === 'create') await createResource(values);
  };

  return (
    <>
      <PageHeaderCustom title={purpose === 'create' ? 'Add' : 'Edit'} />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form
            ref={ref}
            {...formItemLayout}
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
          >
            {currentRoute.fields.map((field) => generateFields(field))}
            <Form.Item>
              <FieldModal currentRoute={currentRoute} />
            </Form.Item>
            <Form.Item wrapperCol={24}>
              <Row justify="start">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => navigate(-1)}
                  icon={<CloseOutlined />}
                >
                  {`${t('buttons.cancel')} `}
                </Button>
                <Button type="add" htmlType="submit" icon={<CheckOutlined />}>
                  {`${t('buttons.save')} `}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    </>
  );
});

DynamicCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired,
  routeId: PropTypes.string.isRequired
};
