import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Input, Modal, Select, Space } from 'antd';
import {
  DatabaseOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { red } from '@ant-design/colors';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

const typeEnums = ['String', 'Number', 'ObjectId', 'Date'];

export const SchemaModal = ({ currentRoute }) => {
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const [schemaModalVisible, setSchemaModalVisible] = useState(false);
  const [form] = Form.useForm();

  const getSchema = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `schemas/builderSchema/${currentRoute.schema_ref}`
      });
      if (data.schema_object) {
        form.setFieldsValue({
          schema: Object.entries(data.schema_object).map(([key, config]) => ({
            key,
            ...config
          }))
        });
      }
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (schemaModalVisible)
      (async () => {
        await getSchema();
      })();

    return () => {
      if (schemaModalVisible) form.resetFields();
    };
  }, [currentRoute, schemaModalVisible]);

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      const body = {};
      values.schema.forEach(({ key, ...config }) => {
        body[key] = config;
      });
      await dispatchAPI('PATCH', {
        url: `schemas/builderSchema/${currentRoute.schema_ref}`,
        body
      });
      setSchemaModalVisible(false);
    } catch (e) {
      setIsLoading(false);
      message(e);
    }
  };

  return (
    <>
      <Modal
        visible={schemaModalVisible}
        onCancel={() => {
          setSchemaModalVisible(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
        okButtonProps={{ loading: isLoading }}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.List name={['schema']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'key']}
                      rules={[{ required: true, message: 'Missing key' }]}
                    >
                      <Input placeholder="Field" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      rules={[{ required: true, message: 'Missing type' }]}
                    >
                      <Select
                        placeholder="Select a type"
                        options={typeEnums.map((type) => ({
                          label: type,
                          value: type
                        }))}
                      />
                    </Form.Item>
                    <Form.Item
                      valuePropName="checked"
                      {...restField}
                      name={[name, 'required']}
                    >
                      <Checkbox>Required</Checkbox>
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{ color: red.primary }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
      <Button
        type="link"
        icon={<DatabaseOutlined />}
        onClick={() => setSchemaModalVisible(true)}
      />
    </>
  );
};

SchemaModal.propTypes = {
  currentRoute: PropTypes.shape({
    schema_ref: PropTypes.string
  }).isRequired
};
