import { useLocation } from 'react-router-dom';

export const DocumentTitle = () => {
  const location = useLocation();
  const splitPath = location.pathname.split('/');

  const path = `/${
    splitPath[2] && !['show', 'edit', 'create'].includes(splitPath[2])
      ? splitPath[2]
      : splitPath[1]
  }`;

  document.title = `ERP Builder${
    path === '/' ? ` | Home` : ` | ${path.replace(/\//g, '')}`
  }`;

  return null;
};
