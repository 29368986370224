import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { useGenerateFormItem } from '../../utils/GenerateFormItem';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';

export const CreateEditRelatedTabs = ({ currentRoute, visible, close }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { projectRoutes, getRoutes } = useBuilderContext();
  const generateFormItem = useGenerateFormItem();
  const [schema, setSchema] = useState();
  const [schemaLoading, setSchemaLoading] = useState(false);
  const [form] = Form.useForm();

  const getSchema = async (value) => {
    const schemaId = projectRoutes.find((r) => r.key === value)?.schema_ref;
    setSchemaLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `schemas/builderSchema/${schemaId}`
      });
      setSchema(data);
      form.resetFields(['lookup_field', 'columns']);
    } catch (e) {
      message(e);
    }
    setSchemaLoading(false);
  };

  const fields = [
    {
      name: ['resource'],
      input: (
        <Select
          options={projectRoutes.map((route) => ({
            label: route.key,
            value: route.key
          }))}
          onChange={getSchema}
        />
      )
    },
    {
      name: ['lookup_field'],
      input: (
        <Select
          allowClear
          loading={schemaLoading}
          options={[
            ...Object.keys(schema?.schema_object || {}),
            'created_at',
            'updated_at'
          ].map((opt) => ({
            label: opt,
            value: opt
          }))}
        />
      )
    },
    {
      name: ['columns'],
      label: 'Columns',
      input: (
        <Form.List name={['columns']} initialValue={['']}>
          {(formListFields, { add, remove }) => (
            <>
              {formListFields.map((field) => (
                <div key={field.key}>
                  <Form.Item
                    name={[field.name, 'key']}
                    rules={[{ required: true }]}
                    label="Key"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name={[field.name, 'title']} label="Title">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'dataIndex']}
                    label="Data index"
                  >
                    <Select
                      allowClear
                      loading={schemaLoading}
                      options={[
                        ...Object.keys(schema?.schema_object || {}),
                        'created_at',
                        'updated_at'
                      ].map((opt) => ({
                        label: opt,
                        value: opt
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'sorter']}
                    valuePropName="checked"
                  >
                    <Checkbox>Sort options</Checkbox>
                  </Form.Item>
                  {!!(formListFields.length > 1) && (
                    <Button
                      danger
                      onClick={() => remove(field.name)}
                      icon={
                        <MinusCircleOutlined style={{ color: red.primary }} />
                      }
                    >
                      Delete column
                    </Button>
                  )}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  style={{ marginTop: 16 }}
                >
                  Add column
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      )
    }
  ];

  const handleCancel = () => {
    form.resetFields();
    close();
  };

  const handleSubmit = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `/routes/${currentRoute._id}/related-tabs`,
        body
      });
      form.resetFields();
      close();
      getRoutes();
    } catch (e) {
      message(e);
    }
  };

  return (
    <Modal visible={visible} onCancel={handleCancel} onOk={form.submit}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {fields.map(generateFormItem)}
      </Form>
    </Modal>
  );
};

CreateEditRelatedTabs.propTypes = {
  currentRoute: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};
