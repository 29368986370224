import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Dropdown, Input, Menu, Select, Space } from 'antd';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined
} from '@ant-design/icons';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import { useHandleResize } from '../../utils/HandleResize';
import {
  routes,
  subRoutes,
  pathSearches
} from '../../utils/constants/userRoutes';
import { useBuilderContext } from '../../contexts/BuilderContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { AddRoute } from '../AddRoute/AddRoute';
import { ExportProject } from '../ExportProject/ExportProject';

const { SubMenu } = Menu;

export const DefaultNavMenu = ({ setCollapsed }) => {
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const {
    projects,
    getProjects,
    project,
    setProject,
    projectRoutes,
    setRouteToEdit,
    setRouteToDelete
  } = useBuilderContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const location = useLocation();
  const { width } = useHandleResize();
  const [newProject, setNewProject] = useState();
  const path = location?.pathname || '/';
  const activeKeys = path
    .replace('/', '')
    .split('/')
    .map((p) => `/${p}`);

  const addProject = async () => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: 'projects',
        body: { name: newProject }
      });
      await getProjects();
      setProject(data._id);
      navigate('/');
      setNewProject(undefined);
    } catch (e) {
      message(e);
    }
  };

  const dropdownRender = (menu) => (
    <div>
      {menu}
      <Divider style={{ margin: '4px 0' }} />
      <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
        <Input
          style={{ flex: 'auto' }}
          value={newProject}
          onChange={(e) => setNewProject(e.target.value)}
        />
        <Button type="link" onClick={addProject} disabled={!newProject}>
          <PlusOutlined /> Add item
        </Button>
      </div>
    </div>
  );

  const handleChangeProject = (v) => {
    setProject(v || null);
    navigate('/');
  };

  const routeMenu = (id) => (
    <Menu>
      <Menu.Item
        icon={<EditOutlined />}
        key="edit"
        onClick={() => setRouteToEdit(id)}
      >
        Edit
      </Menu.Item>
      <Menu.Item
        icon={<DeleteOutlined />}
        key="delete"
        onClick={() => setRouteToDelete(id)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Select
        showSearch
        allowClear
        options={projects.map((p) => ({ label: p.name, value: p._id }))}
        style={{ width: '100%' }}
        placeholder="Select a project"
        value={project}
        onChange={(v) => handleChangeProject(v)}
        dropdownRender={(menu) => dropdownRender(menu)}
      />
      <Menu
        style={{
          padding: '16px 0',
          background: 'var(--clientColor)'
        }}
        selectedKeys={activeKeys}
        defaultOpenKeys={activeKeys}
        mode="inline"
        onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
      >
        {project
          ? [{ path: '/', key: 'home' }, ...projectRoutes].map(
              ({ _id, key, path: routePath, icon, subRoutes: subRtes }) =>
                subRtes?.length ? (
                  <SubMenu key={routePath} icon={icon} title={key}>
                    {subRtes.map(({ key: subKey, path: subPath }) => (
                      <Menu.Item key={subPath}>
                        <NavLink to={`${routePath}${subPath}`}>
                          <span>{subKey}</span>
                        </NavLink>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={routePath}>
                    <NavLink to={routePath}>
                      {icon}
                      <span>
                        {key === 'home' ? t(`basiclayout.menu.${key}`) : key}
                      </span>
                    </NavLink>
                    {key !== 'home' && (
                      <Dropdown
                        trigger={['click']}
                        overlay={() => routeMenu(_id)}
                        placement="bottomLeft"
                      >
                        <Button type="link" style={{ float: 'right' }}>
                          <MoreOutlined style={{ fontSize: 20 }} />
                        </Button>
                      </Dropdown>
                    )}
                  </Menu.Item>
                )
            )
          : Object.entries(routes).map(([menuItem, p]) =>
              subRoutes[menuItem] ? (
                <SubMenu
                  key={p}
                  icon={navMenuLogos[menuItem]}
                  title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                >
                  {Object.entries(subRoutes[menuItem]).map(
                    ([subMenuItem, subPath]) => (
                      <Menu.Item key={subPath} disabled={subPath === ''}>
                        <NavLink
                          to={`${p}${subPath}${
                            pathSearches[subMenuItem]
                              ? pathSearches[subMenuItem]
                              : ''
                          }`}
                        >
                          <span>
                            {t(
                              `basiclayout.submenu.${subMenuItem.toLowerCase()}`
                            )}
                          </span>
                        </NavLink>
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={p} disabled={p === ''}>
                  <NavLink
                    to={`${p}${
                      pathSearches[menuItem] ? pathSearches[menuItem] : ''
                    }`}
                  >
                    {navMenuLogos[menuItem]}
                    <span>
                      {t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
                    </span>
                  </NavLink>
                </Menu.Item>
              )
            )}
        {!!project && (
          <Space direction="vertical" style={{ width: '100%' }}>
            <AddRoute />
            <ExportProject />
          </Space>
        )}
      </Menu>
    </>
  );
};

DefaultNavMenu.propTypes = {
  setCollapsed: PropTypes.func.isRequired
};
