import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useBuilderContext } from '../../contexts/BuilderContext';

export const FieldModal = ({ currentRoute, fieldId }) => {
  const { dispatchAPI } = useAuthContext();
  const { getRoutes } = useBuilderContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (body) => {
    setIsLoading(true);
    try {
      await dispatchAPI(fieldId ? 'PATCH' : 'POST', {
        url: `routes/${currentRoute._id}/fields${fieldId ? `/${fieldId}` : ''}`,
        body
      });
      form.resetFields();
      setVisible(false);
      await getRoutes();
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        onOk={() => form.submit()}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        okButtonProps={{ loading: isLoading }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{ sorter: true }}
        >
          <Form.Item
            name={['name']}
            label="Input name"
            rules={[{ required: true }]}
          >
            <Select allowClear mode="tags" notFoundContent={null} />
          </Form.Item>
          <Form.Item name={['label']} label="Label">
            <Input />
          </Form.Item>
          <Form.Item name={['rules', 0, 'required']} valuePropName="checked">
            <Checkbox>Required</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
      <Button
        type="dashed"
        ghost
        style={{ width: '100%' }}
        icon={<PlusOutlined />}
        onClick={() => setVisible(true)}
      >
        Add a field
      </Button>
    </>
  );
};

FieldModal.propTypes = {
  currentRoute: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  fieldId: PropTypes.string
};

FieldModal.defaultProps = {
  fieldId: undefined
};
